import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from '@pankod/refine-antd';
import './app/i18n';
import './shared/fonts/index.css';
import { mainTheme } from './shared/components/ui/themes';
import './shared/components/ui/styles.css';
import { AuthProvider as OIDCAuthProvider } from 'react-oidc-context';
import {
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
} from './packages/keycloak-client/constants';
import { WebStorageStateStore } from 'oidc-client-ts';

const App = lazy(() =>
  import('./app/App').then((module) => ({
    default: module.default,
  }))
);

const FullScreenLoader = lazy(() =>
  import('./shared/components/full-screen-loader').then((module) => ({
    default: module.FullScreenLoader,
  }))
);

if (!localStorage['activePair']) {
  localStorage.setItem('activePair', 'ETH-BTC');
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <OIDCAuthProvider
    authority={`${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}`}
    client_id={KEYCLOAK_CLIENT_ID}
    redirect_uri={window.location.href}
    accessTokenExpiringNotificationTimeInSeconds={20}
    revokeTokensOnSignout={true}
    userStore={new WebStorageStateStore({ store: window.localStorage })}
  >
    <React.Suspense fallback={<FullScreenLoader />}>
      <ConfigProvider theme={mainTheme}>
        <App />
      </ConfigProvider>
    </React.Suspense>
  </OIDCAuthProvider>
);

reportWebVitals();
