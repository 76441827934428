export const APP_NAME = ''
export const API_URL =
  process.env.REACT_APP_API_URL || // Если переменная задана в .env, используем её
  (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
    ? 'https://development.crypto.extract-sweet.ru/server' // Если переменная true
    : `${window.location.protocol}//${window.location.host.replace('cp.', '')}/server`); // Если переменная false

export const KEYCLOAK_URL =
  process.env.REACT_APP_KEYCLOAK_URL ||
  (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
    ? 'https://id.development.crypto.extract-sweet.ru'
    : `${window.location.protocol}//${window.location.host.replace('cp.', '')}`);

export const ACCOUNT_URL =
  process.env.REACT_APP_ACCOUNT_URL ?? `${window.location.protocol}//${window.location.host.replace('cp.', 'account.')}`;

export const WS_URL = process.env.REACT_APP_WS_URL ?? `wss://wss.${window.location.host}/centrifugo/connection/websocket`;

export const KEYCLOAK_REALM = 'master';
export const KEYCLOAK_CLIENT_ID = 'crypto.web';

export const ACCOUNT_DATA_PROVIDER = 'account';
export const CONFIG_DATA_PROVIDER = 'config';

export const EXCHANGE_DATA_PROVIDER = 'exchange';
export const ETH_COIN_DATA_PROVIDER = 'eth-coin';
export const BTC_COIN_DATA_PROVIDER = 'btc-coin';
export const LTC_COIN_DATA_PROVIDER = 'ltc-coin';

export const SPOT_DATA_PROVIDER = 'spot';
export const P2P_DATA_PROVIDER = 'p2p';

export const BTC_USDT_SPOT_DATA_PROVIDER = 'btcusdt-spot';
export const WMP_DATA_PROVIDER = 'wmp'
export const DEFAULT_PAIR = 'ETH/BTC';

export const DEFAULT_PAIR_WITH_DASH = 'ETH-BTC';
