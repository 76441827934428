import { ThemeConfig } from 'antd';
import { theme } from '@pankod/refine-antd';

export const mainTheme: ThemeConfig = {
  token: {
    screenXXL: 1920,
    screenXXLMin: 1920,
    screenXLMax: 1920,
    screenXL: 1440,
    screenXLMin: 1440,
    screenLGMax: 1440,
    screenLG: 1280,
    screenLGMin: 1280,
    screenMDMax: 1280,
    screenMDMin: 720,
    screenSMMax: 720,
    screenSMMin: 520,
    screenXSMax: 520,
    screenXSMin: 300,
    fontFamily: 'SF Pro Text',
    colorText: '#FFFFFF',
  },
  algorithm: theme.darkAlgorithm,
  components: {
    Timeline: {
      colorBgContainer: '',
    },
    Button: {
      colorBgContainer: '#141414',
      fontSize: 16,
      borderRadius: 8,
      lineHeight: 24,
      controlHeight: 40,
    },
    Checkbox: {
      colorPrimary: '#202124',
      colorBorderSecondary: '#202124',
      colorPrimaryBg: 'white',
      colorBorder: '#202124',
      colorPrimaryTextActive: 'white',
      borderRadiusSM: 0,
    },
    Input: {
      borderRadius: 8,
      colorError: '#E94D4D',
      colorBorder: '#424242',
      colorText: '#FFFFFFD9',
      colorTextHeading: '#FFFFFFD9',
      colorBgContainer: '#141414',
      colorFillAlter: 'transparent',
      colorTextPlaceholder: '#FFFFFF40',
      controlHeight: 32,
    },
    InputNumber: {
      borderRadius: 0,
      borderRadiusLG: 0,
      colorError: '#E94D4D',
    },
    Form: {
      colorError: '#E94D4D',
      colorText: '#FFFFFFD9',
    },
    Typography: {
      colorLink: '#FBFBFF',
      colorLinkHover: '#FBFBFF',
      colorLinkActive: '#FBFBFF',
      fontSizeHeading1: 40,
      colorPrimary: '#FBFBFF',
      colorText: '#FBFBFF',
      colorTextHeading: '#FBFBFF',
      fontSizeHeading2: 32,
    },
    Select: {
      colorText: '#FBFBFF',
      colorBgContainer: '#2B2B36',
      colorBgContainerDisabled: '#2B2B36',
      colorTextLabel: '#FBFBFF',
      colorTextDisabled: '#474755',
      borderRadius: 4,
      borderRadiusSM: 4,
      borderRadiusLG: 4,
      borderRadiusXS: 4,
      controlHeight: 32,
    },
    Steps: {
      controlHeightLG: 0,
    },
    Modal: {
      borderRadiusLG: 24,
      paddingMD: 16,
    },
    DatePicker: {
      controlHeight: 32,
      colorBgElevated: '#2B2B36',
      colorBorder: '#474755',
      colorText: '#FFFFFFD9',
      colorTextHeading: '#FFFFFFD9',
      colorIcon: '#FBFBFF',
      colorTextDisabled: '#767687',
      colorPrimaryBorder: '',
      colorPrimaryHover: '#FBFBFF',
      colorPrimary: '#58BF92',
      colorBgContainer: 'inherit',
      colorTextPlaceholder: '#FBFBFF',
      colorIconHover: '#FBFBFF',
    },
    Table: {
      colorText: '#FFFFFFD9',
      colorTextHeading: '#FFFFFFD9',
      colorBgContainer: '#141414',
      borderRadius: 16,
      borderRadiusLG: 16,
      // colorBorderSecondary: '#FFFFFF0F',
      // colorFillAlter: '#FFFFFF0A',
      // colorTextDisabled: 'rgba(43, 43, 54, 0.35)',
    },
    Tabs: {
      // margin: 24,
      colorText: '#767687',
      colorPrimary: '#FBFBFF',
      colorBorderSecondary: '#474755',
      paddingSM: 18,
      colorPrimaryHover: 'inherit',
      colorPrimaryActive: 'inherit',
      fontSize: 16,
    },
    Badge: {
      colorBorderBg: 'inherit',
    },
    Collapse: {
      colorBorder: '#474755',
      colorBgContainer: '#1C1C27',
    },
    Tag: {
      // colorBorder: 'inherit',
      padding: 8,
    },
    QRCode: {
      borderRadius: 0,
      borderRadiusLG: 0,
    },
    Dropdown: {
      borderRadiusLG: 6,
      borderRadiusSM: 0,
      colorBgElevated: '#424242',
      colorText: '#FBFBFF',
      boxShadowSecondary: 'transparent',
      paddingXXS: 0,
      paddingXS: 0,
      controlItemBgHover: '#424242',
      controlItemBgActive: '#424242',
      controlItemBgActiveHover: '#424242',
    },
    Skeleton: {
      color: 'rgba(43, 43, 54, 0.35)',
      colorGradientEnd: '#2B2B36',
    },
    Tooltip: {
      paddingXS: 12,
      paddingSM: 8,
      colorBgDefault: '#474755',
    },
    Menu: {
      radiusItem: 0,
      colorItemBg: '',
      colorItemBgHover: '#413D5080',
      colorItemBgActive: '#413D5080',
      colorItemBgSelected: '#413D5080',
      controlItemBgActiveHover: '#413D5080',
      colorItemText: '#767687',
      colorItemTextHover: '#FBFBFF',
      colorItemTextSelected: '#FBFBFF',
      marginXXS: 0,
      marginXS: 0,
    },
  },
};
